import Vuex from 'vuex'
import Vue from 'vue'
//import VuexPersist from 'vuex-persist'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

/*const vuexPersist = new VuexPersist({
  key: 'pokr',
  storage: window.localStorage
})*/



//Vuex Store
const store = new Vuex.Store(
  {
    state: {
      authenticated: true,
      odhlasim: "",
      user: {},
      programy: [],
      admin: false
    },
    mutations: {
      setAuthentication(state, status) {
        state.authenticated = status;
      },
      setUser(state, user) {
        state.user = user;
        var d = new Date();
        var a = new Date();
        if (a.getHours() > 20) {
          var s = 27 - a.getHours();
          d.setHours(d.getHours() + s);
        } else {
          while (a.getDate() == d.getDate()) {
            d.setHours(d.getHours() + 2);
          }
        }

        var n = d.toString();
        window.console.log(n);
        state.odhlasim = n;
      },

      setProgramy(state, programy) {
        state.programy = programy;
      },



      /*
      setID_uzivatel(state, id_uzivatel) {
        state.id_uzivatel_login = id_uzivatel;
    },
    */

      clearUser(state) {
        state.user = {}
        state.authenticated = false;
      },

      resetStore(state) {
        state.user = {};
        state.authenticated = false;
        state.programy = [];
        state.admin = false;
      }




    },
    actions: {
      getUser({ commit }) {
        axios
          .get('/api/user/get')
          .then((response) => {
            commit('setUser', response.data)
          })
      },

      getProgramy({ commit }) {
        axios
          .get('/api/program/all/')
          .then((response) => {
            commit('setProgramy', response.data);
            window.console.log(response.data);
          })
      },


      getProgramy_old({ commit }) {
        axios
          .get('https://pokr.fis.vse.cz/api/program/all_old/')
          .then((response) => {
            commit('setProgramyOld', response.data)
          })
      },


      /*
                  getId_uzivatel({ commit } ) {
                    axios
                      .get('/api/user/id_login_user')
                      .then((response) => {
                          commit('setID_uzivatel', response.data )
                      })
                  },
      */


      logout({ commit }) {
        axios
          .get('/api/user/logout')
          .then((response) => {
            window.console.log(response.data);
            commit('clearUser');
          })

      }
    },
    plugins: [createPersistedState({
      storage: window.sessionStorage,
    })],
  }
);

export default store;