<template>
  <div class="login">
    <div class="errorbox" v-if="errors.length">
      <p v-for="error in errors" :key="error.index">{{error}}</p>
    </div>

    <div class="login-field column10-3" @keyup.once="error=''">
      <div class="login-field__logo"></div>
      <h2 class="login-field__title">Přihlášení</h2>Vítejte v aplikaci
      <strong>Programu osobního a kvalifikačního rozvoje (POKR)</strong>, která slouží k podpoře tvůrčích aktivit pracovníků Fakulty informatiky a statistiky VŠE.
      <br />
      <br />Pro optimální fungování využijte pro práci s aplikací webové prohlížeče Chrome nebo Firefox.
      <br />
      <br />
      <strong>Přihlaste se prosím pomocí svého VŠE účtu.</strong>
      <a href="shibboleth" class="login-field__submit">Přihlásit se VŠE účtem</a>
    </div>
    <div class="column6-10">
      <div class="bg-skola">
        <div class="scroll-container">
          <div class="column1">
            <h2>Co to je POKR a k čemu slouží</h2>
            <p>
              <strong>P</strong>rogram
              <strong>O</strong>sobního a
              <strong>K</strong>valifikačního
              <strong>R</strong>ozvoje (
              <strong>POKR</strong>) slouží k podpoře tvůrčích aktivit pracovníků FIS. V jeho rámci je možné zažádat o podporu těchto aktivit, a to nejdéle na období jednoho kalendářního roku.
            </p>

            <p>Při prvním přihlášení zadejte svoji pracovní roli (doktorand, asistent, odborný asistent, docent nebo profesor) a katedru, na níž máte majoritní úvazek.</p>
            <p>Při přihlášení do programu zadejte oblast Vašich aktivit („Tvůrčí činnosti“ nebo „Pedagogika“), a potom pokračujte v zadání konkrétního programu. </p>
           
          </div>




          <div class="column1"><h3>Tvůrčí činnost</h3> </div>

          <div class="column2">
            <p>O podporu v rámci programu “Publikační činnost” na rok 2024 je možné žádat <strong> od 1. 2. do 22. 2. do 23:59. </strong> U ostatních programů celoročně. U ostatních programů celoročně. Podporu je možné pro tento rok získat v následujících programech spadajících do Tvůrčí činnosti:</p>

            <p>
              <strong>Publikační činnost</strong> - Program je určen pro zájemce, kteří by chtěli podpořit výhradně svoje publikační aktivity. Umožní požádat o podporu pro zamýšlené publikace výhradně do časopisů, které jsou indexovány ve WoS a které měly AIS v roce 2022 v prvním až třetím kvartilu. Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů). Fakulta zjišťuje, ke které z vymezených fakultních domén se publikační výstup vztahuje: <br>

              <ol start="1">
                <li >Ekonomické aspekty stárnutí</li>
                <li>Demografické studie</li>
                <li>Lidský kapitál v souvislostech, financování vysokého školství</li>
                <li>Sociodemografické studie</li>
                <li>Statistické zachycení a modelování ekonomických procesů</li>
                <li>Optimalizační modely v podnikové sféře</li>
                <li>Analýza transakčních dat</li>
                <li>Speciální data v ekonometrii</li>
                <li>Sémantický web, ontologické inženýrství - vzory v ontologiích</li>
                <li>Aplikace datových věd</li>
                <li>Srozumitelnost a interpretovatelnost data miningových modelů</li>
                <li>Data Management (Správa dat)</li>
                <li>Dotazníková šetření v socioekonomické oblasti</li>
                <li>Modely hodnocení efektivnosti a výkonnosti</li>
                <li>Řízení a vývoj IS/ICT</li>
                <li>Aplikované prediktivní modely</li>
                <li>Sémantický web, ontologické inženýrství - znalostní grafy, grafové databáze, vizualizace grafových dat.</li>
                <li>Machine learning v cloudu a automatizace ML</li>
                <li>Analýza dezinformací v textech</li>
                <li>Efektivita multimediální komunikace (aplikace v marketingové komunikaci a prezentaci entit)</li>
                <li>Efektivita UX a UI</li>
                <li>Multimédia ve vzdělávání (video, grafika, podcast)</li>
                <li>Vícerozměrné metody</li>
                <li>Information Security</li>
                <li>Human-Computer Interaction (Interakce člověk-počítač)</li>
              </ol>
            </p>
           
            <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku.</p>


           <p>
              <strong>Vědecká monografie</strong> -
             Podpořena bude celá publikace (ne jednotlivé kapitoly) monografického charakteru (splňuje podmínky monografie dle metodiky 17+) v prestižním zahraničním nakladatelství ve světovém jazyce. Podmínkou pro získání podpory je uzavřená smlouva s tímto nakladatelstvím.
            </p>






          </div>

          <div class="column2">
            <p>
              <strong>Jmenovací řízení </strong> -
              Program slouží k podpoře přípravy uchazeče o akademickou hodnost profesor. Její součástí je podpora publikace výhradně do časopisů, které jsou indexovány ve WoS a které měly AIS v roce 2022 v prvním či druhém kvartilu. Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů) a relevantní k oblasti jmenovacího řízení. Navíc musí předkladatel mít minimálně poloviční podíl na podporovaném článku.
            </p>
            <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku. Na konci roku předkládá pracovník jako další výstup kompletní dokumentaci k zahájení jmenovacího řízení.</p>


            <p>
              <strong>Habilitační řízení</strong> - Program slouží k podpoře přípravy uchazeče o hodnost docent. Její součástí je podpora publikace výhradně do časopisů, které jsou indexovány ve WoS a které měly AIS v roce 2022 v prvním či druhém kvartilu. Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů) a relevantní k oblasti habilitačního řízení. Navíc musí předkladatel mít minimálně poloviční podíl na podporovaném článku. Na konci roku předkládá pracovník jako další výstupy 80 % habilitační práce a dokumentaci k zahájení habilitačního řízení.
            </p>
            <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku.</p>

            <p>
              <strong>Hostující profesor</strong> - Program slouží k podpoře internacionalizace FIS. Cílem je podpořit činnost zahraničních pedagogů na FIS s přesahem do vědecko-výzkumné činnosti. Ve formuláři žadatel vyplní informaci o působení hostujícího profesora a o jeho vědecko-výzkumných aktivitách na FIS. Na tyto činnosti mu bude přidělena finanční podpora.
            </p>
            <p>
              <strong>Podpora podání nového externího projektu</strong> - Program slouží k podpoře přípravy nového projektu, který bude podávat pracovník FIS (případně s kolektivem). Při podávání žádosti uchazeč vyplní název projektu, agenturu, k níž projekt chce podat, spolupracovníky (maximální počet pracovníků na projektu jsou čtyři) a jejich podíl na projektu. 
            </p>
            <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku.</p>

            <p>
              <strong>Podpora podání opraveného externího projektu</strong> - Program slouží k podpoře přípravy již jednou podaného a neúspěšného projektu, který bude podávat pracovník FIS (případně s kolektivem). Při podávání žádosti uchazeč vyplní název projektu, agenturu, k níž projekt podává, spolupracovníky (maximální počet pracovníků na projektu jsou čtyři) a jejich podíl na projektu.
            </p>
            <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu - zde se bude jednat o minimálně jednu položku. </p>


<!--
            <h3>Pedagogika</h3>

            <p>
              <strong>Tvorba e-learningového modulu</strong> - Program slouží k rozvoji výuky prostřednictvím tvorby a aplikace e-learningového modulu, kterým může být kombinace vzdělávacího videa, komentovaného záznamu práce s aplikací, audio nahrávek, pracovních listů nebo jiných podpor, které zvýší efektivitu studia během přímé i nepřímé výuky. Pracovník se zadáním do systému POKR zavazuje k přípravě podkladů a účasti na realizaci. Technickou realizaci e-learningu (natáčení videa, audia, grafické práce apod.) zajistí Katedra multimédií, jejíž pověřený pracovník bude zároveň od okamžiku schválení projektu průběžně spolupracovat a konzultovat přípravu. Při podávání žádosti uchazeč vyplní studijní předmět, pro který by chtěl e-learningové podpory připravit a rámcovou představu o podobě e-learningového modulu. Součástí programu je účast na školeních pro různé dovednosti potřebné pro přípravu e-learningu.
            </p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "login",
  data() {
    return {
      errors: [],
      inputEmail: "",
      inputPassword: ""
    };
  },

  created() {
    this.$store.dispatch("resetStore");
  },

  mounted() {
    this.$gtag.pageview({
      page_path: "/login"
    }),
      this.$http.get("/api/user/check_user").then(response => {
        if (response.data == "user" || response.data == "admin") {
          this.error = false;

          this.$store.dispatch("getProgramy");
          this.$store.commit("setAuthentication", true);
          this.$store.dispatch("getUser").then(() => {
            setTimeout(() => {
              if (response.data == "admin") {
                this.$router.replace("/admin-decide");
              } else {
                this.$router.replace("/");
              }
            }, 500);
          });
        }
      });
  }
};
</script>