//Import knihoven
import 'babel-polyfill'
import Vue from 'vue'
import Router from 'vue-router'
import Modal from 'vue-js-modal'
import axios from 'axios'


//Import root componentu
import App from './App.vue'

//Stranky
import Login from './Login.vue'

const Home = () => import(/* webpackChunkName: "group-uzivatel" */ './Home.vue')
const HistorickeProgramy = () => import(/* webpackChunkName: "group-uzivatel" */ './HistorickeProgramy.vue')

const admin_HistorickeProgramy = () => import(/* webpackChunkName: "group-uzivatel" */ './admin_HistorickeProgramy.vue')


const OAplikaci = () => import(/* webpackChunkName: "group-uzivatel" */ './oAplikaci.vue')

const AdminDecide = () => import(/* webpackChunkName: "group-admin" */ './AdminDecide.vue')
const Admin = () => import(/* webpackChunkName: "group-admin" */ './Admin.vue')


//Analytics
import VueGtag from "vue-gtag";


import store from './store.js'

Vue.prototype.$http = axios

import './style/main.scss'

//Pouzit knihovny
Vue.use(Router);
Vue.use(Modal, { dynamic: true, dynamicDefaults: { clickToClose: false } })


//Detect vue devtool
 Vue.config.devtools = true;



Vue.config.productionTip = false

function checkLogin() {

  var ted = new Date();
  var odhlasim = Date.parse(store.state.odhlasim);
  if (!store.state.authenticated || (ted > odhlasim)) {

    if (router.app.$route.name != "Login") {
      axios.get("/api/user/logout").then(() => {
        store.dispatch("logout").then(() => {
          window.localStorage.clear();
          router.push("/login");
        });
      });
    }



    /*let data = {
      id_user: store.state.user.id_uzivatel
    }

    axios
      .post('/api/user/reload', data)
      .then((response) => {
        window.console.log(response.data)
        if (response.data == "betterRefresh") {
          store.dispatch('getUser')
        }
        else if (response.data != "allright") {
          store.dispatch('logout')
          router.push('/login');
        }
      })*/

  }


}

setInterval(checkLogin, 10000);

//URL pro router
const routes = [
  {
    path: '/login',
    component: Login,
    name: "Login"
  },
  {
    path: '/',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!store.state.authenticated) next('/login')
      //else if (store.state.user.status == 2) next('/admin')
      else next()
    },
    name: "Home"
  },
  {
    path: '/historicke',
    component: HistorickeProgramy,
    beforeEnter: (to, from, next) => {
      if (!store.state.authenticated) next('/login')
      else next()
    },
    name: "Historické programy"
  },


  {
    path: '/admin_historicke',
    component: admin_HistorickeProgramy,
    beforeEnter: (to, from, next) => {
      if (!store.state.authenticated) next('/login')
      else next()
    },
    name: "Historické programy admin"
  },




  {
    path: '/o',
    component: OAplikaci,
    beforeEnter: (to, from, next) => {
      if (!store.state.authenticated) next('/login')
      else next()
    },
    name: "O aplikaci"
  },
  {
    path: '/admin-decide',
    component: AdminDecide,
    beforeEnter: (to, from, next) => {
      if (!store.state.authenticated) next('/login')
      else if (store.state.user.status != 2) next('/')
      else next()
    },
    name: "Výběr role pro administrátora"
  },
  /*
  TEST COMPONENT

  {
    path: '/test',
    component: test
  },
  */
  {
    path: '/admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (!store.state.authenticated) next('/login')
      else if (store.state.user.status != 2) next('/')
      else next()
    },
    name: "Administrace"
  }

]

/*window.onunload = function () {
  axios.get("/api/user/logout").then(() => {
    store.dispatch("logout").then(() => {
      window.localStorage.clear();
      router.push("/login");
    });
  });
}*/

window.addEventListener("beforeunload", function () {
  if (router.app.$route.name != "Login") {
    axios.get("/api/user/logout").then(() => {
      return true;
    });
  }
});

//Vytvoreni routeru
const router = new Router({
  routes,
  mode: 'history'
})


Vue.use(VueGtag, {
  config: { id: "UA-158079884-1" },
  appName: 'POKR'
}, router);

//Hlavni vue instance
new Vue({
  el: "#app",
  router,
  render: h => h(App),
  store: store,

}).$mount('#app')
